import React from "react"
import styled, { ThemeProvider } from "styled-components"
import Layout from "components/LandingLayout"
import metaTranslations from "../../lib/utils"
import SEO from "components/seo"
import theme from "../../themes"
import NavbarComponent from "../../components/Landing/Navbar/Navbar"
import NavbarFeature from "../../components/Landing/NavbarFeature/NavbarFeature"
import FeatureHero from "../../components/Landing/FeatureHero/FeatureHero"
import { windowGlobal } from "../../config"
import FeatureListContainer from "../../components/Landing/FeatureListContainer/FeatureListContainer";
import CalendarImage from "../../images/landing/calendar_test.svg";
import VirtualIntructionParents from "../../images/landing/virtual_instruction_parents.svg";
import CalendarParents from "../../images/landing/calendar_parents.svg";
import EasyMeetingsImage from "../../images/landing/easy_meetings.svg";
import CalendarDsktp from '../../images/landing/calendar_hero_dsktp.png';
import CalendarMobile from '../../images/landing/Calendar_Mobile_wbkg_3x.png';
import { WithTrans } from "../../language/withTrans"

const FEATURE_VIEW = "CalendarView"
const COLOR_CALENDAR = "rgb(68,201,78,0.1)";

const FOR_TEACHERS_ARRAY = [
  {
    title: "calendar",
    description: "Flexible scheduling",
    image: CalendarImage,
  },
  {
    title: "virtual",
    description: "Virtual instruction tools",
    image: VirtualIntructionParents,
  },
];

const FOR_PARENTS_ARRAY = [
  {
    title: "calendar",
    description: "See all the events that are important to your kids",
    image: CalendarParents,
  },
  {
    title: "virtual",
    description: "Easy access to video conference meetings",
    image: EasyMeetingsImage,
  },
];

const Calendar = ({ lang }) => {
  windowGlobal.localStorage.setItem("LANDING_LOCALE", lang);

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[
          {
            name: "og:image",
            content:
              "https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg",
          },
        ]}
      />
      <ThemeProvider theme={theme}>
        <NavbarComponent lang={"es"} />
        <NavbarFeature view={FEATURE_VIEW} />
        <FeatureHero
          title={'Manage virtual and in-person events'}
          imageDesktop={CalendarDsktp}
          imageMobile={CalendarMobile}
        />

        <FeatureListContainer
          color={COLOR_CALENDAR}
          itemsForTeachers={FOR_TEACHERS_ARRAY}
          itemsForParents={FOR_PARENTS_ARRAY}

        />
      </ThemeProvider>
    </Layout>
  );
};

export default WithTrans()(Calendar);
